import React from "react";
import { Button, Col, FormGroup } from "react-bootstrap";
import OrderSearchSelect from "./OrderSearchSelect";
import StyledDatePicker from "../../../../components/common/StyledDatePicker";
import {
    dateEndChanged,
    dateStartChanged,
    externalsChanged,
    generateCustomerReport,
    loadExternalsForOrder,
    orderChanged,
    STATE_DEFAULT,
    stateChanged
} from "../../actions/customerPanelExternalsActions";
import { connect } from "react-redux";
import moment from "moment/moment";
import styled from "styled-components";

const OrderContainer = styled.div`
    width: 200px;
    margin-bottom: 15px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 16px;
`;

@connect(
    ({ clientPanel, router }) => ({
        ...clientPanel.clientPanelExternals,
        pathName: router.location.pathname
    }),
    dispatch => ({
        loadExternalsForOrder: _ => dispatch(loadExternalsForOrder()),
        orderChanged: ev => {
            dispatch(orderChanged(ev.value));
            dispatch(stateChanged(STATE_DEFAULT));
        },
        dateStartChanged: p => dispatch(dateStartChanged(p)),
        dateEndChanged: p => dispatch(dateEndChanged(p)),
        reset: _ => {
            dispatch(stateChanged(STATE_DEFAULT));
            dispatch(externalsChanged([]));
            dispatch(orderChanged());
        },
        generateCustomerReport: _ => dispatch(generateCustomerReport())
    })
)
export default class Searchbar extends React.Component {
    constructor() {
        super();
        this.state = {
            receivedOrderId: false
        };
    }

    setOrder = _ => {
        this.setState({
            receivedOrderId: true
        });
    };

    optionSelected = ev => {
        if (!ev) {
            this.props.reset();
            return;
        }
        this.props.orderChanged(ev);
    };
    componentWillMount() {
        let orderId = null;
        orderId = this.props.pathName.split("/")[4];
        if (orderId) {
            this.setOrder();
        }
        this.props.dateStartChanged(
            moment()
                .startOf("day")
                .startOf("month")
        );
        this.props.dateEndChanged(moment().startOf("day"));
        this.props.orderChanged({ value: orderId });
        orderId ? this.props.loadExternalsForOrder() : null;
    }
    render() {
        return (
            <div>
                <Col sm={6} md={3}>
                    <div className="flex-row">
                        <b className="inline-label">Order:</b>
                        <OrderContainer>
                            <OrderSearchSelect
                                clearable={!this.state.receivedOrderId}
                                style={{ width: "200px" }}
                                value={this.props.order}
                                onChange={this.optionSelected}
                                disabled={this.state.receivedOrderId}
                            />
                        </OrderContainer>
                    </div>
                </Col>
                <Col sm={6} md={3}>
                    <div className="flex-row">
                        <b className="inline-label">Start date:</b>
                        <FormGroup>
                            <StyledDatePicker
                                dateFormat="yyyy-MM-dd"
                                todayButton={"Today"}
                                showWeekNumbers
                                placeholderText="Click to select a date"
                                wide
                                selected={this.props.dateStart}
                                onChange={this.props.dateStartChanged}
                            />
                        </FormGroup>
                    </div>
                </Col>
                <Col sm={6} md={3}>
                    <div className="flex-row">
                        <b className="inline-label">End date:</b>
                        <FormGroup>
                            <StyledDatePicker
                                dateFormat="yyyy-MM-dd"
                                todayButton={"Today"}
                                showWeekNumbers
                                placeholderText="Click to select a date"
                                wide
                                selected={this.props.dateEnd}
                                onChange={this.props.dateEndChanged}
                            />
                        </FormGroup>
                    </div>
                </Col>
                <Col sm={6} md={3}>
                    <ButtonsContainer>
                        <Button onClick={this.props.loadExternalsForOrder}>Show</Button>
                        {this.props.state === "STATE_LOADED" && (
                            <Button onClick={this.props.generateCustomerReport}>Generate excel</Button>
                        )}
                    </ButtonsContainer>
                </Col>
            </div>
        );
    }
}

import React from "react";
import { Glyphicon } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { logoutRequested, STATE_LOADING, STATE_LOGGED_IN } from "../../actions/authActions";
import AnimatedHider from "../../components/common/AnimatedHider";
import StyledIcon from "../../components/common/StyledIcon";
import {
    ButtonIcon,
    LineSeperator,
    NavbarButton,
    NavbarItemsRight,
    NavbarDropdownContent,
    UserMenuListItem,
    UserMenuListItemIcon,
    UserNameContainer
} from "../../components/navbarStyles";

@connect(
    ({ auth }) => ({
        user: auth.user,
        authState: auth.authState
    }),
    dispatch => ({
        logoutRequested: _ => dispatch(logoutRequested())
    })
)
export default class UserDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showUserMenu: false };
    }
    showUserMenu = () => {
        this.setState({ showUserMenu: true });
    };

    hideUserMenu = () => {
        this.setState({ showUserMenu: false });
    };
    getShortDisplayName() {
        let nameSplit = this.props.user.displayName.split(" ");
        if (nameSplit.length >= 2) {
            return nameSplit[1];
        }
        return this.props.user.displayName;
    }
    render() {
        if (this.props.authState === STATE_LOADING) {
            return <StyledIcon spin className={this.props.className} />;
        } else if (this.props.authState === STATE_LOGGED_IN) {
            return (
                <div>
                    <NavbarButton onClick={this.showUserMenu}>
                        <ButtonIcon>
                            <Glyphicon glyph="user" />
                            <UserNameContainer
                                hideOnMobile
                            >{`Hello, ${this.getShortDisplayName()}!`}</UserNameContainer>
                        </ButtonIcon>
                    </NavbarButton>

                    <OutsideClickHandler onOutsideClick={this.hideUserMenu}>
                        <AnimatedHider show={this.state.showUserMenu}>
                            <NavbarDropdownContent responsiveOffset>
                                <LineSeperator />
                                <UserMenuListItem onClick={this.props.logoutRequested}>
                                    <UserMenuListItemIcon glyph="log-out" />
                                    Logout
                                </UserMenuListItem>
                                <LineSeperator />
                            </NavbarDropdownContent>
                        </AnimatedHider>
                    </OutsideClickHandler>
                </div>
            );
        } else {
            return [];
        }
    }
}

import React from "react";
import { connect } from "react-redux";
import SidebarMenu from "../../components/common/SidebarMenu";

@connect(({ clientPanel }) => ({
    showMainMenu: clientPanel.clientPanels.showMainMenu
}))
export default class CustomerMenu extends React.Component {
    render() {
        return (
            <SidebarMenu
                expanded={this.props.showMainMenu}
                items={[
                    {
                        show: true,
                        key: "customerpanel-dashboard",
                        label: "Dashboard",
                        to: "/customer",
                        glyph: "th"
                    }
                ]}
            />
        );
    }
}

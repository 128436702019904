import React from "react";
import { Glyphicon, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import { CSSTransition } from "react-css-transition";
import { connect } from "react-redux";
import { Route } from "react-router";
import styled from "styled-components";
import {
    PERMISSION_NEW_EMPLOYEE_NOTIFICATION,
    PERMISSION_NEW_USER_NOTIFICATION,
    PERMISSION_RECIEVE_CUSTOM_NOTIFICATIONS
} from "../../../../common/src/permissions";
import { showSidebarMenuChanged } from "../../actions/dashboardUIActions";
import LogoSmall from "../../components/common/LogoSmall";
import {
    ButtonIcon,
    CustomLink,
    LeftMenuContainer,
    LogoContainer,
    LogoTextContainer,
    MenuContainer,
    NavbarButton,
    NavbarContainer,
    NavbarItemsRight,
    transitionMainMenuSizeStyles,
    NavbarGroup
} from "../../components/navbarStyles";
import { refreshDesktop } from "../../DesktopsPage/actions/desktopsActions";
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import DesktopMenu from "./DesktopMenu";
import NotificationsDropdown from "./NotificationsDropdown";
import UserDropdown from "./UserDropdown";
import { withRouter } from "react-router";

@withRouter
@connect(
    state => ({
        notificationEnabled:
            userHasPermissionSelector(state, PERMISSION_NEW_EMPLOYEE_NOTIFICATION) ||
            userHasPermissionSelector(state, PERMISSION_NEW_USER_NOTIFICATION) ||
            userHasPermissionSelector(state, PERMISSION_RECIEVE_CUSTOM_NOTIFICATIONS),
        showSidebar: state.dashboardUI.showSidebarMenu
    }),
    dispatch => ({
        refreshDesktop: _ => dispatch(refreshDesktop(false)),
        showSidebarMenuChanged: p => dispatch(showSidebarMenuChanged(p))
    })
)
export default class DashboardNavBar extends React.Component {
    toggleSidebar = () => {
        this.props.showSidebarMenuChanged(!this.props.showSidebar);
    };
    homeIconClicked = () => {
        this.props.refreshDesktop();
        this.props.showSidebarMenuChanged(false);
        window.scrollTo(0, 0);
    };
    render() {
        return (
            <NavbarContainer sticky responsive desktopsMode={this.props.location.pathname === "/dashboard/desktops"}>
                <NavbarGroup left>
                    <LogoContainer>
                        <LogoSmall />
                    </LogoContainer>

                    <NavbarButton onClick={this.toggleSidebar}>
                        <ButtonIcon>
                            <Glyphicon glyph="menu-hamburger" />
                        </ButtonIcon>
                    </NavbarButton>
                    <CustomLink to="/dashboard/desktops" white onClick={this.homeIconClicked}>
                        <NavbarButton>
                            <ButtonIcon>
                                <Glyphicon glyph="home" />
                            </ButtonIcon>
                        </NavbarButton>
                    </CustomLink>
                </NavbarGroup>

                <Route path="/dashboard/desktops" exact component={DesktopMenu} />

                <NavbarGroup right>
                    {this.props.notificationEnabled && <NotificationsDropdown />}

                    <UserDropdown />
                </NavbarGroup>
            </NavbarContainer>
        );
    }
}

import { makeActionCreator, encodeParams, adjustForTimezone } from "../../../utils";
import { apiFetch } from "../../../actions/apiActions";
import download from "downloadjs";
import { toast } from "react-toastify";
import { showGenerateReportModalChanged } from "../../../OfficePage/actions/approvedReportsTableActions";
import moment from "moment";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";

export const ORDER_CHANGED = "CLIENT_PANEL.CLIENT_PANEL_EXTERNALS.ORDER_CHANGED";
export const DATE_START_CHANGED = "CLIENT_PANEL.CLIENT_PANEL_EXTERNALS.DATE_START_CHANGED";
export const DATE_END_CHANGED = "CLIENT_PANEL.CLIENT_PANEL_EXTERNALS.DATE_END_CHANGED";
export const STATE_CHANGED = "CLIENT_PANEL.CLIENT_PANEL_EXTERNALS.STATE_CHANGED";
export const EXTERNALS_CHANGED = "CLIENT_PANEL.CLIENT_PANEL_EXTERNALS.EXTERNALS_CHANGED";
export const ORDER_INFORMATION_CHANGED = "CLIENT_PANEL.CLIENT_PANEL_EXTERNALS.ORDER_INFORMATION_CHANGED";

export const orderChanged = makeActionCreator(ORDER_CHANGED, "payload");
export const dateStartChanged = makeActionCreator(DATE_START_CHANGED, "payload");
export const dateEndChanged = makeActionCreator(DATE_END_CHANGED, "payload");
export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const externalsChanged = makeActionCreator(EXTERNALS_CHANGED, "payload");
export const orderInformationChanged = makeActionCreator(ORDER_INFORMATION_CHANGED, "payload");

export function loadExternalsForOrder() {
    return async (dispatch, getState) => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let data;
            const order = getState().clientPanel.clientPanelExternals.order;
            const dateStart = getState().clientPanel.clientPanelExternals.dateStart;
            const dateEnd = getState().clientPanel.clientPanelExternals.dateEnd;
            //new general summary
            let generalSummaryPerPart = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/part-report?order=${order}&dateStart=${dateStart}&dateEnd=${dateEnd}`
                )
            );
            if (!generalSummaryPerPart.ok) {
                throw new Error(generalSummaryPerPart.message);
            }
            let generalSummaryPerPartData = await generalSummaryPerPart.json();

            //new working hours
            let workingHours = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/hour-report?order=${order}&dateStart=${dateStart}&dateEnd=${dateEnd}`
                )
            );
            if (!workingHours.ok) {
                throw new Error(workingHours.message);
            }
            let workingHoursData = await workingHours.json();

            //new details part report
            let detailedPartReport = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/detail-part-report?order=${order}&dateStart=${dateStart}&dateEnd=${dateEnd}`
                )
            );
            if (!detailedPartReport.ok) {
                throw new Error(detailedPartReport.message);
            }
            let detailedPartReportData = await detailedPartReport.json();

            //new daily part report
            let dailyPartReport = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/daily-part-report?order=${order}&dateStart=${dateStart}&dateEnd=${dateEnd}`
                )
            );
            if (!detailedPartReport.ok) {
                throw new Error(detailedPartReport.message);
            }
            let dailyPartReportData = await dailyPartReport.json();

            let orderInformation = await dispatch(apiFetch(encodeParams`/api/customer-panel/orders/${order}/info`));
            if (!orderInformation.ok) {
                throw new Error(orderInformation.message);
            }
            let orderInformationData = await orderInformation.json();
            dispatch(orderInformationChanged(orderInformationData));

            let letterColumnsDescription = await dispatch(
                apiFetch(encodeParams`/api/customer-panel/orders/${order}/letter-columns-desc`)
            );
            if (!letterColumnsDescription.ok) {
                throw new Error(letterColumnsDescription.message);
            }
            let letterColumnsDescriptionData = await letterColumnsDescription.json();

            data = {
                generalSummaryPerPart: generalSummaryPerPartData,
                workingHours: workingHoursData,
                detailedPartReport: detailedPartReportData,
                dailyPartReport: dailyPartReportData,
                letterColumnsDescription: letterColumnsDescriptionData
            };
            dispatch(externalsChanged(data));
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            console.error(e);
        }
    };
}

export function generateCustomerReport() {
    return async (dispatch, getState) => {
        try {
            let clientPanelData = getState().clientPanel.clientPanelExternals;
            let resp = await dispatch(
                apiFetch("/api/customer-panel/externals/generate-excel", undefined, {
                    dateEnd: moment(clientPanelData.dateEnd).format("YYYY-MM-DD"),
                    dateStart: moment(clientPanelData.dateStart).format("YYYY-MM-DD"),
                    order: clientPanelData.order
                })
            );
            if (resp.status !== 200) {
                throw new Error((await resp.json()).message);
            }

            let data = await resp.blob();
            download(data);
        } catch (e) {
            toast(e.message, { type: "error" });
        }
    };
}

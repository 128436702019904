import React from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import AutoRow from "./AutoRow";
import AutoCell from "./AutoCell";
import IconButton from "../IconButton";

@connect(
    (state, ownProps) => ({
        rows: ownProps.model.rowsSelector(state)
    }),
    dispatch => ({ dispatch })
)
export default class AutoTable extends React.Component {
    firstCellRefs = [];
    handleFirstCellRef = index => ref => {
        this.firstCellRefs[index] = ref;
    };
    render() {
        let header, content;
        if (window.matchMedia("(min-device-width: 960px)").matches) {
            header = (
                <this.props.model.header
                    disabled={this.props.disabled}
                    model={this.props.model}
                    runtimeProps={this.props.runtimeProps}
                />
            );

            // TODO: generate key using model selector
            content = this.props.rows.map((r, i) => {
                let nextRowRef = this.firstCellRefs[i + 1];
                let focusNextRowCallback = null;
                if (nextRowRef && nextRowRef.focus) {
                    focusNextRowCallback = _ => nextRowRef.focus();
                }
                return (
                    <AutoRow
                        key={r._id || i}
                        allRows={this.props.rows}
                        model={this.props.model}
                        rowIndex={i}
                        row={r}
                        disabled={this.props.disabled}
                        rowsLength={this.props.rows.length}
                        focusNextRow={focusNextRowCallback}
                        firstCellRef={this.handleFirstCellRef(i)}
                        focusLastRowOnNextUpdate={() => {
                            setTimeout(() => {
                                this.firstCellRefs[this.firstCellRefs.length - 1].focus();
                            }, 1);
                        }}
                    />
                );
            });
        } else {
            header = null;
            content = this.props.rows.map((row, rowIndex) =>
                this.props.model.columns.map((c, columnIndex) => [
                    <tr key={(row._id || rowIndex) + columnIndex + "_H"}>
                        <th>{c.title}</th>
                    </tr>,
                    <tr>
                        <AutoCell
                            key={(row._id || rowIndex) + columnIndex + "_V"}
                            model={this.props.model}
                            row={row}
                            rowIndex={rowIndex}
                            column={c}
                            columnIndex={columnIndex}
                            disabled={this.props.disabled}
                            rowsLength={this.props.rows.length}
                        />
                    </tr>
                ])
            );
        }
        let footer = null;
        if (this.props.model.addRowActionCreator) {
            footer = (
                <tfoot>
                    <tr>
                        <td colSpan={this.props.model.columns.length}>
                            <IconButton
                                disabled={this.props.disabled}
                                wide
                                onClick={_ => this.props.dispatch(this.props.model.addRowActionCreator())}
                            />
                        </td>
                    </tr>
                </tfoot>
            );
        }
        return (
            <Table className={this.props.className}>
                {header}
                <tbody>{content}</tbody>
                {footer}
            </Table>
        );
    }
}

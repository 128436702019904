import React from "react";
import { connect } from "react-redux";
import { STATE_LOADED } from "../../actions/customerPanelExternalsActions";
import { TableBody, TableCustom } from "./Styles";
import { setTitle } from "./External";

@connect(({ clientPanel }) => clientPanel.clientPanelExternals)
export default class GeneralSummaryPerPart extends React.Component {
    render() {
        let header = "";
        let content = "";
        if (this.props.state === STATE_LOADED) {
            let generalSummaryPerPart = this.props.externals.generalSummaryPerPart;
            header = (
                <tr>
                    {generalSummaryPerPart.header.map(i => <th title={setTitle(i.val, this.props.externals.letterColumnsDescription)}>{i.val}</th>)}
                </tr>
            );
            content = generalSummaryPerPart.rows.map( (r, index) => {
                let row = (<tr key={r.partNumber+index}>
                    <td>{r.partNumber}</td>
                    <td>{r.totalChecked}</td>
                    <td>{r.fromThisOk}</td>
                    <td>{r.reworked}</td>
                    <td>{r.nok}</td>
                    {generalSummaryPerPart.header.map((h, i) => {
                        if(i>4 && i< generalSummaryPerPart.header.length - 1) {
                            return (<td>{r[h.val]}</td>);
                        }
                    })}
                    <td>{r.totalOk}</td>
                </tr>);
                return row;
            });
        }
        return (
            <TableCustom responsive={true}>
                <TableBody>
                    <tbody>
                        {header}
                        {content}
                    </tbody>
                </TableBody>
            </TableCustom>
        );
    }
}
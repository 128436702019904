import subtotalsSelector from "./subtotalsSelector";

/**
 * Checks whether the remarks field has been filled when the total is zero.
 * @param {*} state 
 */
export default function validateQATotalRemarks(state) {
    if (!state.reports.remarksDescription.remarksDescriptionContent.trim()) {
        if (subtotalsSelector(state.reports.productQA.rows).totalChecked <= 0) {
            return false; // remarks must be filled when totalChecked is zero
        }
    }
    return true; // remarks are okay
}

import React from "react";
import { connect } from "react-redux";
import { STATE_LOADED } from "../../actions/customerPanelExternalsActions";
import { TableBody, TableCustom } from "./Styles";
import styled from "styled-components";

const Td = styled.td`
    vertical-align: middle !important;
    min-width: 100px;
`;

@connect(({ clientPanel }) => clientPanel.clientPanelExternals)
export default class DetailedPartReport extends React.Component {
    render() {
        let header = "";
        let content = "";
        let summaryHeader = "";
        let summary = "";
        if (this.props.state === STATE_LOADED) {
            let detailedPartReport = this.props.externals.detailedPartReport;
            header = (
                <tr>
                    {detailedPartReport.header && detailedPartReport.header.map(i => <th>{i.val}</th>)}
                </tr>
            );
            if(detailedPartReport.rows) {
                content = detailedPartReport.rows.map((r, index) => {
                    let row = (<tr key={r.partNumber.val + index}>
                        {r.partNumber.rowSpan !== 0 && (<Td rowSpan={r.partNumber.rowSpan}>{r.partNumber.val}</Td>)}
                        {r.ec.map(r => {
                            let res = r.rowSpan !== 0 ? (<Td rowSpan={r.rowSpan}>{r.val}</Td>) : null;
                            return res;
                        })}
                        <Td>{r.totalChecked}</Td>
                        <Td>{r.fromThisOk}</Td>
                        <Td>{r.reworked}</Td>
                        <Td>{r.nok}</Td>
                        {r.nokDetails.map(d => {
                            return (<td>{d.val}</td>);
                        })}
                        <Td>{r.totalOk}</Td>
                    </tr>);
                    return row;
                });
            }
            summaryHeader = (
                <tr>
                    {detailedPartReport.summaryHeader && detailedPartReport.summaryHeader.map( i => <td>{i.val}</td>)}
                </tr>
            );
            summary = (
                <tr>
                    {detailedPartReport.summary && detailedPartReport.summary.map( i => <td>{i}</td>)}
                </tr>
            );
        }
        return (
            <TableCustom responsive={true}>
                <TableBody>
                    <tbody>
                        {header}
                        {content}
                        {summaryHeader}
                        {summary}
                    </tbody>
                </TableBody>
            </TableCustom>
        );
    }
}
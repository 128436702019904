import React from "react";
import styled, { css } from "styled-components";
import { Glyphicon } from "react-bootstrap";
import { CSSTransition, transit } from "react-css-transition/lib/index";
import { NavLink, Link } from "react-router-dom";
import theme from "../../theme";

const SidebarWrapper = styled.div`
    overflow-y: hidden;
`;

const SidebarPusher = styled.div`
    transition: 500ms;
    width: 80px;
    height: 5px;

    @media (max-width: 600px) {
        width: 0;
        ${props =>
            props.expanded &&
            css`
                width: 240px;
            `}
    }
    ${props =>
        props.expanded &&
        css`
            width: 240px;
        `}
`;

const SidebarContainer = styled.div`
    z-index: 500;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 16px;
    overflow: hidden;
    min-height: calc(100vh - 60px);
    background: #fff;
    margin-bottom: -5000px; /* any large number will do */
    padding-bottom: 5000px;

    transition: 500ms;
    width: 80px;
    position: fixed;

    @media (max-width: 600px) {
        width: 0;
        ${props =>
            props.expanded &&
            css`
                width: 240px;
            `}
    }
    ${props =>
        props.expanded &&
        css`
            width: 240px;
        `}
`;

const LinkWithClass = props => <NavLink {...props} activeClassName="sidebar-link-active" />;

const SidebarLink = styled(LinkWithClass)`
    text-decoration: none !important;
    display: flex;
    color: rgba(0, 0, 0, 0.86);
    list-style-type: none;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
    transition: background-color 300ms ease-in-out;
    height: 40px;
    padding: 0 16px;
    &:hover {
        background-color: #e1e1e1;
    }
    &.sidebar-link-active {
        color: ${theme.customerPrimaryColor};
        font-weight: bold;
    }
`;

const SidebarLinkList = styled.ul`
    padding: 0;
`;
const MenuItemText = styled.div`
    padding-top: 10px;
`;

const MenuItemIcon = styled(({ expanded, ...rest }) => <Glyphicon {...rest} />)`
    padding-top: 10px;
    padding-right: 10px;
    margin-left: 20px;
    font-size: 15px;
    ${props =>
        props.expanded &&
        css`
            font-size: 18px;
            margin-left: 15px;
        `}
`;

const LineSeperator = styled.div`
    border-top: solid 1px ${props => (props.black ? "rgba(0,0,0,.55)" : "rgba(0,0,0,0)")};
    margin: 8px 0 6px 0;
`;

export default class SidebarMenu extends React.Component {
    render() {
        const transitionMainMenuSizeStyles = {
            defaultStyle: window.innerWidth > 800 ? { width: "80px" } : { width: "0px" },
            enterStyle: {
                width: transit("240px", 500, "ease-in-out")
            },
            leaveStyle:
                window.innerWidth > 800
                    ? { width: transit("80px", 500, "ease-in-out") }
                    : { width: transit("0px", 500, "ease-in-out") },
            activeStyle: {
                width: "240px"
            }
        };

        return (
            <div>
                <SidebarPusher expanded={this.props.expanded} />
                <SidebarWrapper>
                    <SidebarContainer expanded={this.props.expanded}>
                        <SidebarLinkList>
                            <LineSeperator />
                            {this.props.items
                                .filter(item => item.show)
                                .map(item => (
                                    <SidebarLink to={item.to} key={item.key} onClick={this.props.onItemClicked}>
                                        <MenuItemIcon glyph={item.glyph || "th"} />
                                        <MenuItemText
                                            style={this.props.expanded ? { display: "block" } : { display: "none" }}
                                        >
                                            {item.label}
                                        </MenuItemText>
                                    </SidebarLink>
                                ))}

                            <LineSeperator black />
                        </SidebarLinkList>
                    </SidebarContainer>
                </SidebarWrapper>
            </div>
        );
    }
}

import { makeActionCreator } from "../../utils";
import { makeApiUrl } from "../../api";
import { push } from "react-router-redux";
import { apiFetch, tokenUpdated } from "../../actions/apiActions";
import { parseRawQueryString } from "../../urlUtils";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_ERROR = "STATE_ERROR";
export const STATE_LOADING = "STATE_LOADING";

export const EMAIL_CHANGED = "LOGIN.EMAIL_CHANGED";
export const PASSWORD_CHANGED = "LOGIN.PASSWORD_CHANGED";
export const LOGIN_STATE_CHANGED = "LOGIN.LOGIN_STATE_CHANGED";
export const ERROR_MESSAGE_CHANGED = "LOGIN.ERROR_MESSAGE_CHANGED";

export const emailChanged = makeActionCreator(EMAIL_CHANGED, "payload");
export const passwordChanged = payload => ({
    type: PASSWORD_CHANGED,
    payload,
    _diagnosticsIgnore: true // do not save this action in the action buffer, sensitive data
});
export const errorMessageChanged = makeActionCreator(ERROR_MESSAGE_CHANGED, "payload");
export const loginStateChanged = makeActionCreator(LOGIN_STATE_CHANGED, "payload");

export function login() {
    return async (dispatch, getState) => {
        dispatch(loginStateChanged(STATE_LOADING));
        let loginUrl = makeApiUrl("/api/auth/login");
        let data, response;
        let state = getState();
        try {
            response = await fetch(loginUrl, {
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    email: state.login.email,
                    password: state.login.password
                })
            });
            data = await response.json();
        } catch (e) {
            dispatch(loginStateChanged(STATE_ERROR));
            dispatch(passwordChanged("")); // clear the password
            dispatch(errorMessageChanged(e.message));
            return;
        }
        if (response.status === 200) {
            dispatch(loginStateChanged(STATE_DEFAULT));
            dispatch(tokenUpdated(data.token)); // store the token in memory and localStorage
            dispatch(passwordChanged("")); // clear the password
            dispatch(emailChanged(""));
            let backUrl = parseRawQueryString(getState().router.location.search).back;
            if (backUrl) {
                dispatch(push(backUrl));
            } else {
                let resp = await dispatch(apiFetch("/api/auth/current-user"));
                let data = await resp.json();
                if(data.group.permissions.indexOf("CLIENT_PANEL")!==-1) {
                    dispatch(push("/customer"));
                } else {
                    dispatch(push("/dashboard/desktops"));
                }
            }
        } else {
            dispatch(loginStateChanged(STATE_ERROR));
            dispatch(passwordChanged("")); // clear the password
            dispatch(errorMessageChanged(data.message || "Unknown error"));
        }
    };
}

import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

/**
 * Adds zero to number of string if it is shorter than 2 characters.
 * @param {String|Number} data
 */
export function leftPadWithZero(data) {
    return data.toString().length < 2 ? "0" + data.toString() : data.toString();
}

/**
 * Converts minutes to HH:MM.
 * @param {Number} minutes
 */
export function minutesToTimeString(minutes) {
    return leftPadWithZero(Math.floor(minutes / 60)) + ":" + leftPadWithZero(minutes % 60);
}

/**
 * Converts HH:MM to minutes.
 * @param {String} clockTime
 */
export function clockTimeToMinutes(clockTime) {
    let segments = clockTime.split(":");
    return parseInt(segments[0]) * 60 + parseInt(segments[1]);
}

export function minutesToHours(minutes) {
    return parseFloat((minutes / 60).toFixed(2));
}

export function getWorkingHours(row) {
    let toMinutes = clockTimeToMinutes(row.toTime);
    let fromMinutes = clockTimeToMinutes(row.fromTime);
    if (isNaN(toMinutes) || isNaN(fromMinutes)) {
        return null;
    }
    let workingHours;
    if (toMinutes > fromMinutes) {
        workingHours = toMinutes - fromMinutes;
    } else {
        workingHours = 24 * 60 - fromMinutes + toMinutes;
    }
    let brake = parseInt(row.brake);
    if (isNaN(brake)) {
        brake = 0;
    }
    let arrivalTime = parseInt(row.arrivalTime);
    if (isNaN(arrivalTime)) {
        arrivalTime = 0;
    }
    return workingHours - brake + arrivalTime;
}

export function getWorkingHoursRange(row, controlDate, setStartOfDay = false) {
    let fromMinutes = clockTimeToMinutes(row.fromTime);
    let toMinutes = clockTimeToMinutes(row.toTime);
    if (isNaN(toMinutes) || isNaN(fromMinutes)) {
        return null;
    }
    let fromDate = moment(controlDate);
    if (setStartOfDay) {
        fromDate.startOf("day");
    }
    let toDate = fromDate.clone();
    fromDate.add(fromMinutes, "minutes");
    if (fromMinutes > toMinutes) {
        toDate.add(24, "hours");
    }
    toDate.add(toMinutes, "minutes");

    if (fromMinutes < 60 * 6) {
        // before 06:00 this is the next day
        toDate.add(24, "hours");
        fromDate.add(24, "hours");
    }
    fromDate.subtract(moment(fromDate).isDST() ? 2 : 1, "h");
    toDate.subtract(moment(toDate).isDST() ? 2 : 1, "h");
    return moment.range(fromDate, toDate);
}

export function getWorkingHoursWithShifts(row, controlDate) {
    let output = {
        normal: 0,
        night: 0,
        saturday: 0,
        sunday: 0
    };

    let workingHoursRange = getWorkingHoursRange(row, controlDate);
    if (workingHoursRange == null) {
        return null;
    }
    let startOfWeek = moment(controlDate).startOf("isoweek");

    let ranges = [];
    for (let i = 0; i < 8; i++) {
        // add ranges for normal and night hours for this week and next monday
        if (i === 5 || i === 6) continue; // exclude weekends
        let startOfNormalHours = startOfWeek
            .clone()
            .add(i, "days")
            .hours(6)
            .minutes(0);

        let startOfNightHours = startOfNormalHours.clone().hours(22);
        ranges.push({
            range: moment.range(startOfNormalHours, startOfNightHours),
            type: "normal"
        });

        ranges.push({
            range: moment.range(startOfNightHours, startOfNightHours.clone().add(8, "hours")),
            type: "night"
        });
    }
    let startOfSaturdayHours = startOfWeek
        .clone()
        .add(5, "days")
        .hours(6)
        .minutes(0);
    let startOfSundayHours = startOfWeek
        .clone()
        .add(6, "days")
        .hours(6)
        .minutes(0);
    ranges.push({
        range: moment.range(startOfSaturdayHours, startOfSundayHours),
        type: "saturday"
    });

    ranges.push({
        range: moment.range(startOfSundayHours, startOfSundayHours.clone().add(24, "hours")),
        type: "sunday"
    });
    ranges.push({
        range: moment.range(startOfWeek, startOfWeek.clone().set("hours", 6)),
        type: "sunday"
    });
    ranges.forEach(r => {
        let intersected = r.range.intersect(workingHoursRange);
        if (intersected) {
            output[r.type] += intersected.duration("minutes");
        }
    });
    return output;
}

export function isReportOpen(report) {
    //if not available time when worker finished return true (report is incomplite)
    for (let t of report.lastVersion[0].employeeTimeTable.rows) {
        if (isNaN(clockTimeToMinutes(t.toTime))) {
            return true;
        }
    }
    return false;
}

export function startOfWorkInReport(report) {
    let array = [];
    for (let e of report.lastVersion[0].employeeTimeTable.rows) {
        let minutes = clockTimeToMinutes(e.fromTime);
        minutes > 359 ? array.push(minutes) : array.push(minutes + 1440);
    }
    return minutesToTimeString(array.sort((a, b) => a - b)[0] % 1440);
}

export function endOfWorkInReport(report, onlyCommunicat = false) {
    let array = [];
    for (let e of report.lastVersion[0].employeeTimeTable.rows) {
        let minutesTo = clockTimeToMinutes(e.toTime);
        let minutesFrom = clockTimeToMinutes(e.fromTime);
        minutesTo <= 360 || minutesTo < minutesFrom || minutesFrom < 360
            ? array.push(minutesTo + 1440)
            : array.push(minutesTo);
    }
    array = array.sort((a, b) => b - a);
    if (onlyCommunicat) {
        return array[0] > 1440 ? true : false;
    }
    return minutesToTimeString(array[0] % 1440);
}

export function totalInReport(report, externals = false) {
    let result = 0;
    if (externals) {
        for (let e of report.lastVersion[0].productQA) {
            result += e.totalChecked;
        }
    } else {
        for (let e of report.lastVersion[0].productQA.rows) {
            result += e.totalChecked;
        }
    }

    return result;
}

export function nokInReport(report, externals = false) {
    let result = 0;
    if (externals) {
        for (let e of report.lastVersion[0].productQA) {
            result += e.nok;
        }
    } else {
        for (let r of report.lastVersion[0].productQA.rows) {
            result += r.nok;
        }
    }
    return result;
}

export function letterColumnsContentInReport(report) {
    let result = [],
        tmp = [];
    for (let r of report.lastVersion[0].productQA.rows) {
        if (r.letterColumnsContent && r.letterColumnsContent.length > 0) {
            tmp = r.letterColumnsContent.map(l => {
                return l !== null ? l : 0;
            });
            if (result.length === 0) {
                result = tmp;
            } else {
                tmp.map((i, index) => {
                    result[index] += i;
                });
            }
        }
    }
    return result;
}

export function reworkedInReport(report, externals = false) {
    let result = 0;
    if (externals) {
        for (let e of report.lastVersion[0].productQA) {
            result += e.reworked;
        }
    } else {
        for (let e of report.lastVersion[0].productQA.rows) {
            result += e.reworked;
        }
    }
    return result;
}

export function getSumWorkingHours(rows) {
    let sumWorkingMinutes = 0;

    for (let row of rows) {
        let fromTimeInMinutes = clockTimeToMinutes(row.fromTime);
        let toTimeInMinutes = clockTimeToMinutes(row.toTime);
        if (toTimeInMinutes > fromTimeInMinutes) {
            sumWorkingMinutes += toTimeInMinutes - fromTimeInMinutes;
        } else if (toTimeInMinutes === fromTimeInMinutes) {
            sumWorkingMinutes += 24 * 60;
        } else {
            sumWorkingMinutes += toTimeInMinutes;
            sumWorkingMinutes += 24 * 60 - fromTimeInMinutes;
        }
    }
    let result = Math.floor(sumWorkingMinutes / 60);
    isNaN(result) ? (result = "") : null;
    let minutes = sumWorkingMinutes % 60;
    isNaN(minutes) ? (minutes = 0) : null;
    minutes !== 0 ? (minutes > 0 && minutes < 10 ? (result += `:0${minutes}`) : (result += `:${minutes}`)) : null;
    return result;
}

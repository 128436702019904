import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { Container } from "./Styles";
import { STATE_LOADING } from "../../../../OfficePage/actions/approvedReportsTableActions";
import StyledIcon from "../../../../components/common/StyledIcon";
import OrderInformation from "./OrderInformation";
import ContactPerson from "./ContactPerson";
import GeneralSummaryPerPart from "./GeneralSummaryPerPart";
import WorkingHours2 from "./WorkingHours2";
import DetailedPartReport from "./DetailedPartReport";
import DailyPartReport from "./DailyPartReport";

const ErrorContainer = styled(Container)`
    text-align: center;
    height: 70px;
    vertical-align: middle;
`;

const ErrorMessage = styled.p`
    font-size: 18px;
    margin: auto;
`;

const DataContainer = styled.div`
    margin-top: 7px;
`;

const CustomRow = styled(Row)`
    margin-left: 0px;
    margin-right: 0px;
`;

@connect(({ clientPanel }) => clientPanel.clientPanelExternals)
export default class ExternalsList extends React.Component {
    render() {
        return (
            <div>
                {this.props.state === STATE_LOADING &&
                <StyledIcon spin center size="big" />
                }
                <DataContainer className="bottom-divider">
                    {this.props.externals && this.props.state === "STATE_LOADED" && <CustomRow>
                        <Col md={12}>
                            <Container>
                                <OrderInformation />
                            </Container>
                        </Col>
                        <CustomRow>
                            <ContactPerson />
                        </CustomRow>
                        <Col md={6}>
                            <Container>
                                <GeneralSummaryPerPart />
                            </Container>
                        </Col>
                        <Col md={6}>
                            <Container>
                                <WorkingHours2 />
                            </Container>
                        </Col>
                    </CustomRow>}
                    {this.props.externals && this.props.state === "STATE_LOADED" && <CustomRow>
                        <Col md={12}>
                            <Container>
                                <DetailedPartReport />
                            </Container>
                        </Col>
                    </CustomRow>}
                    {this.props.externals && this.props.state === "STATE_LOADED" && <CustomRow>
                        <Col md={12}>
                            <Container>
                                <DailyPartReport />
                            </Container>
                        </Col>
                    </CustomRow>}
                    {this.props.state === "STATE_ERROR" && <CustomRow>
                        <Col md={12}>
                            <ErrorContainer>
                                <ErrorMessage>
                                    Sorry, no reports in this range of dates. Please change order number or dates range.
                                </ErrorMessage>
                            </ErrorContainer>
                        </Col>
                    </CustomRow>}
                </DataContainer>
            </div>
        );
    }
}

import React from "react";
import { Button } from "react-bootstrap";
import styled, { css } from "styled-components";

// eslint-disable-next-line no-unused-vars
export default styled(
    ({
        withMargin,
        marginLeft,
        wide,
        marginBottom,
        noMarginTop,
        marginRight,
        customBackgroundColor,
        dark,
        noMarginRight,
        paddedFullWidth,
        ...rest
    }) => <Button {...rest} />
).attrs({
    bsStyle: props => props.bsStyle || "success"
})`
        ${props =>
            props.wide &&
            css`
                min-width: 110px;
            `}
        ${props =>
            (props.wide || props.withMargin) &&
            css`
                margin-top: 8px;
                margin-right: 2px;
            `}
            ${props =>
                props.paddedFullWidth &&
                css`
                    padding: 10px 10px;
                    width: 100%;
                `}
        ${props =>
            props.marginLeft &&
            css`
                margin-left: 10px;
            `}
        ${props =>
            props.marginRight &&
            css`
                margin-right: 10px;
            `}
        ${props =>
            props.marginBottom &&
            css`
                margin-bottom: 10px;
            `}
        ${props =>
            props.noMarginTop &&
            css`
                margin-top: 0;
            `}
            ${props =>
                props.noMarginRight &&
                css`
                    margin-right: 0;
                `}
        ${props =>
            props.inline &&
            css`
                display: inline;
            `}
        ${props =>
            props.customBackgroundColor &&
            css`
                background-color: ${props.customBackgroundColor};
            `}
        ${props =>
            props.dark &&
            css`
                color: white;
            `}
    `;

import React from "react";
import DatePicker from "react-datepicker";
import styled, { css } from "styled-components";
import classnames from "classnames";
import { isMoment } from "moment";

export default styled(({ wide: _, ...rest }) => (
    <DatePicker
        {...rest}
        selected={isMoment(rest.selected) ? (rest.selected.isValid() ? rest.selected.toDate() : null) : rest.selected}
    />
)).attrs({
    className: props => classnames(props.className, "form-control")
})`
    text-align: center;
    width: 136px;
    ${props =>
        props.wide &&
        css`
            width: 100%;
        `};
`;

import {
    STATE_DEFAULT,
    STATE_CHANGED,
    GROUPS_CHANGED,
    GROUP_PERMISSION_CHANGED,
    GROUP_ADDED,
    GROUP_NAME_CHANGED,
    RESET_CHANGED_PERMISSIONS,
    GROUP_EDITABLE_GROUPS_CHANGED
} from "../actions/groupsActions";
import { permissionsInfo } from "../../../../common/src/permissions";

export const initialState = {
    state: STATE_DEFAULT,
    changedPermissions: [],
    groups: null
};

export default function usersListReducer(state = initialState, action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case GROUPS_CHANGED:
            return {
                ...state,
                groups: action.payload
            };
        case GROUP_PERMISSION_CHANGED: {
            // remove or add permission based on action payload
            let addToChangedPermissionUpdate = null;
            if (state.changedPermissions.indexOf(action.permission) === -1) {
                addToChangedPermissionUpdate = {
                    changedPermissions: [...state.changedPermissions, action.permission]
                };
            }
            return {
                ...state,
                ...addToChangedPermissionUpdate,
                groups: state.groups.map(g => {
                    if (g._id !== action.groupId) return g;
                    if (action.payload === true && g.permissions.indexOf(g.permission) === -1) {
                        return {
                            ...g,
                            permissions: [
                                ...(permissionsInfo[action.permission].solo ? [] : g.permissions), // if the permission  is a solo permission we cannot allow any others
                                action.permission
                            ]
                        };
                    } else {
                        const findDependenciesOfPermission = perm => {
                            return Object.keys(permissionsInfo)
                                .filter(
                                    k =>
                                        permissionsInfo[k].requirements &&
                                        permissionsInfo[k].requirements.indexOf(perm) !== -1
                                )
                                .reduce((arr, p) => [...arr, p, ...findDependenciesOfPermission(p)], []);
                        };
                        const permissionsToUncheck = findDependenciesOfPermission(action.permission);
                        return {
                            ...g,
                            permissions: g.permissions.filter(
                                p => p !== action.permission && permissionsToUncheck.indexOf(p) === -1
                            )
                        };
                    }
                })
            };
        }
        case GROUP_ADDED:
            return {
                ...state,
                groups: [...state.groups, action.payload]
            };
        case GROUP_NAME_CHANGED:
            return {
                ...state,
                groups: state.groups.map(g => {
                    if (g._id !== action.groupId) return g;
                    return {
                        ...g,
                        name: action.payload
                    };
                })
            };
        case RESET_CHANGED_PERMISSIONS:
            return {
                ...state,
                changedPermissions: []
            };
        case GROUP_EDITABLE_GROUPS_CHANGED:
            if (action.payload) {
                return {
                    ...state,
                    groups: state.groups.map(g => {
                        if (g._id !== action.groupId || g.editableGroups.indexOf(action.groupToEdit) !== -1) return g;
                        return {
                            ...g,
                            editableGroups: [...g.editableGroups, action.groupToEdit]
                        };
                    })
                };
            } else {
                return {
                    ...state,
                    groups: state.groups.map(g => {
                        if (g._id !== action.groupId) return g;
                        return {
                            ...g,
                            editableGroups: g.editableGroups.filter(eg => eg !== action.groupToEdit)
                        };
                    })
                };
            }
        default:
            return state;
    }
}

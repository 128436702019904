import React from "react";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import AutoTable from "../../../components/common/AutoTable";
import AutoTableHeader from "../../../components/common/AutoTable/AutoTableHeader";
import SimpleText from "../../../components/common/AutoTable/cells/SimpleText";
import ConfirmableButton from "../../../components/common/ConfirmableButton";
import StyledButton from "../../../components/common/StyledButton";
import StyledIcon from "../../../components/common/StyledIcon";
import { parseRawQueryString } from "../../../urlUtils";
import { encodeParams } from "../../../utils";
import { loadTemplates, STATE_LOADED, STATE_LOADING, deleteTemplate } from "../../actions/templatesListActions";
import FilterHeaderField from "../FilterHeaderField";
import TopControlsRow from "../TopControlsRow";
import AlignedFlex from "../../../components/common/AlignedFlex";
import LinkCell from "../../../components/common/AutoTable/cells/LinkCell";
import { Link } from "react-router-dom";

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    > * {
        margin-right: 3px;
    }
`;
const ButtonsCell = styled.td`
    width: 100px;
`;

@connect(
    state => state.hr.templatesList,
    dispatch => ({
        dispatch,
        deleteTemplate: id => dispatch(deleteTemplate(id))
    })
)
export default class TemplatesListPage extends React.Component {
    componentDidMount() {
        this.loadData();
    }
    componentDidUpdate(oldProps) {
        if (oldProps.location.search !== this.props.location.search) {
            this.loadData();
        }
    }
    loadData() {
        const query = parseRawQueryString(this.props.location.search.replace(/^\?/g, ""));
        this.props.dispatch(loadTemplates(query));
    }
    render() {
        const query = parseRawQueryString(this.props.location.search.replace(/^\?/g, ""));
        const model = {
            header: AutoTableHeader,
            rowsSelector: state => state.hr.templatesList.templates,
            columns: [
                {
                    title: "Name",
                    valueSelector: r => r.name,
                    cell: LinkCell,
                    to: r => encodeParams`/dashboard/hr/templates/${r._id}`
                },
                {
                    title: "Actions",
                    valueSelector: r => r._id,
                    tdComponent: ({ children }) => <ButtonsCell>{children}</ButtonsCell>,
                    cell: ({ row, value }) => (
                        <ButtonsContainer>
                            <LinkContainer to={encodeParams`/dashboard/hr/templates/${value}`}>
                                <StyledButton bsStyle="primary">
                                    <StyledIcon glyph="pencil" />
                                </StyledButton>
                            </LinkContainer>
                            <ConfirmableButton
                                onConfirm={_ => this.props.deleteTemplate(value)}
                                message={`Do you really want to delete template ${row.name}?`}
                                key="delete"
                            />
                        </ButtonsContainer>
                    )
                }
            ]
        };
        let content = null;

        if (this.props.state === STATE_LOADED) {
            content = <AutoTable model={model} />;
        } else if (this.props.state === STATE_LOADING) {
            content = (
                <div>
                    <StyledIcon spin center size="big" />
                </div>
            );
        }
        return (
            <div>
                <h3>
                    <Link to="/dashboard/hr" exact>
                        <StyledIcon glyph="arrow-left" />
                    </Link>
                    &nbsp;HR Templates
                </h3>

                <TopControlsRow>
                    <FilterHeaderField noPagination query={query} queryKey="filter" placeholder="Filter..." />
                    <LinkContainer to="/dashboard/hr/templates/new">
                        <StyledButton bsStyle="success" wide>
                            <StyledIcon glyph="plus" />
                        </StyledButton>
                    </LinkContainer>
                </TopControlsRow>
                {content}
            </div>
        );
    }
}

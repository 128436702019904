import moment from "moment";
/**
 * Checks if date of birth is a valid leading PESEL number.
 * @param {Object} row
 */
export default function validateDateOfBirth(row) {

    let dateOfBirthValid;
    if (!row.employeeData) {
        dateOfBirthValid = true;
    } else {
        if (row.employeeData.dateOfBirth.length !== 6) {
            dateOfBirthValid = false;
        } else {
            dateOfBirthValid = moment(row.employeeData.dateOfBirth.toString(), "YYMMDD").isValid();
        }
    }
    return dateOfBirthValid;

}

import { makeActionCreator, ENTITY_STATUS_UNCHANGED, encodeParams } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { push } from "react-router-redux";
import { loadCompaniesList } from "./companiesListActions";
import { deleteAccess } from "../../InvitationModal/actions/invitationActions";
import { toast } from "react-toastify";
import getValidationErrors from "../selectors/getValidationErrors";
import { errorsChanged } from "./validationActions";
import React from "react";

export const STATE_NO_COMPANY = "STATE_NO_COMPANY";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_NEW = "STATE_NEW";
export const STATE_SAVING = "STATE_SAVING";

export const COMPANY_STATE_CHANGED = "COMPANIES.COMPANY.COMPANY_STATE_CHANGED";
export const COMPANY_ERROR_CHANGED = "COMPANIES.COMPANY.COMPANY_ERROR_CHANGED";
export const COMPANY_RESET = "COMPANIES.COMPANY.COMPANY_RESET";

export const COMPANY_ID_CHANGED = "COMPANIES.COMPANY.COMPANY_ID_CHANGED";
export const COMPANY_NAME_CHANGED = "COMPANIES.COMPANY.COMPANY_NAME_CHANGED";
export const COMPANY_ADDRESS1_CHANGED = "COMPANIES.COMPANY.ADDRESS1_CHANGED";
export const COMPANY_ADDRESS2_CHANGED = "COMPANIES.COMPANY.ADDRESS2_CHANGED";

export const COMPANY_EMPLOYEE_ADDED = "COMPANIES.COMPANY.COMPANY_EMPLOYEE_ADDED";
export const COMPANY_EMPLOYEE_REMOVED = "COMPANIES.COMPANY.COMPANY_EMPLOYEE_REMOVED";
export const COMPANY_EMPLOYEE_NAME_CHANGED = "COMPANIES.COMPANY.COMPANY_EMPLOYEE_NAME_CHANGED";
export const COMPANY_EMPLOYEE_EMAIL_CHANGED = "COMPANIES.COMPANY.COMPANY_EMPLOYEE_PHONE_EMAIL_CHANGED";
export const COMPANY_EMPLOYEE_PHONE_NUMBER_CHANGED = "COMPANIES.COMPANY.COMPANY_EMPLOYEE_PHONE_NUMBER_CHANGED";
export const COMPANY_EMPLOYEE_ACCESS_TO_PANEL_CHANGED = "COMPANIES.COMPANY.COMPANY_EMPLOYEE_ACCESS_TO_PANEL_CHANGED";
export const COMPANY_EMPLOYEE_UNIQUE_CHANGED = "COMPANIES.COMPANY.COMPANY_EMPLOYEE_UNIQUE_CHANGED";
export const COMPANY_EMPLOYEE_EXIST_IN_EMPLOYEES_ID_CHANGED =
    "COMPANIES.COMPANY.COMPANY_EMPLOYEE_EXIST_IN_EMPLOYEES_ID_CHANGED";
export const COMPANY_EMPLOYEE_ACCOUNT_STATUS_CHANGED = "COMPANIES.COMPANY.COMPANY_EMPLOYEE_ACCOUNT_STATUS_CHANGED";
export const COMPANY_EMPLOYEES_CHANGED = "COMPANIES.COMPANY.COMPANY_EMPLOYEES_CHANGED";
export const COMPANY_TO_DELETE_ACCESS_ADDED = "COMPANIES.COMPANY.COMPANY_TO_DELETE_ACCESS_ADDED";
export const COMPANY_TO_DELETE_ACCESS_REMOVED = "COMPANIES.COMPANY.COMPANY_TO_DELETE_ACCESS_REMOVED";

export const companyStateChanged = makeActionCreator(COMPANY_STATE_CHANGED, "payload");
export const companyReset = makeActionCreator(COMPANY_RESET);
export const companyIdChanged = makeActionCreator(COMPANY_ID_CHANGED, "payload");
export const companyNameChanged = makeActionCreator(COMPANY_NAME_CHANGED, "payload");
export const companyAddress1Changed = makeActionCreator(COMPANY_ADDRESS1_CHANGED, "payload");
export const companyAddress2Changed = makeActionCreator(COMPANY_ADDRESS2_CHANGED, "payload");
export const companyErrorChanged = makeActionCreator(COMPANY_ERROR_CHANGED, "payload");
export const companyEmployeesChanged = makeActionCreator(COMPANY_EMPLOYEES_CHANGED, "payload");
export const companyEmployeeAdded = makeActionCreator(COMPANY_EMPLOYEE_ADDED);
export const companyEmployeeRemoved = makeActionCreator(COMPANY_EMPLOYEE_REMOVED, "employeeIndex");
export const companyEmployeeNameChanged = makeActionCreator(COMPANY_EMPLOYEE_NAME_CHANGED, "employeeIndex", "payload");
export const companyEmployeeEmailChanged = makeActionCreator(
    COMPANY_EMPLOYEE_EMAIL_CHANGED,
    "employeeIndex",
    "payload"
);
export const companyEmployeePhoneNumberChanged = makeActionCreator(
    COMPANY_EMPLOYEE_PHONE_NUMBER_CHANGED,
    "employeeIndex",
    "payload"
);
export const companyEmployeeAccessToPanelChanged = makeActionCreator(
    COMPANY_EMPLOYEE_ACCESS_TO_PANEL_CHANGED,
    "employeeIndex",
    "payload"
);
export const companyEmployeeUniqueChanged = makeActionCreator(
    COMPANY_EMPLOYEE_UNIQUE_CHANGED,
    "employeeIndex",
    "payload"
);
export const companyEmployeeExistInEmployeesIdChanged = makeActionCreator(
    COMPANY_EMPLOYEE_EXIST_IN_EMPLOYEES_ID_CHANGED,
    "employeeIndex",
    "payload"
);
export const companyEmployeeAccountStatusChanged = makeActionCreator(
    COMPANY_EMPLOYEE_ACCOUNT_STATUS_CHANGED,
    "employeeIndex",
    "payload"
);
export const companyToDeleteAccessAdded = makeActionCreator(COMPANY_TO_DELETE_ACCESS_ADDED, "payload");
export const companyToDeleteAccessDeleted = makeActionCreator(COMPANY_TO_DELETE_ACCESS_REMOVED);

export function loadCompany(id) {
    return async dispatch => {
        try {
            // fetch company data
            dispatch(companyStateChanged(STATE_LOADING));
            let companyResp = await dispatch(apiFetch(encodeParams`/api/companies/${id}`));

            let companyData = await companyResp.json();
            if (!companyResp.ok) {
                throw new Error("An error has occured: " + (companyData.message || "Unknown error"));
            }
            if (!companyData || typeof companyData !== "object") {
                throw new Error("Data returned by API is not an object!");
            }

            // fetch employee data
            let emoloyeesResp = await dispatch(apiFetch(encodeParams`/api/companies/${id}/contacts`));

            let employeesData = await emoloyeesResp.json();
            if (!emoloyeesResp.ok) {
                throw new Error("An error has occured: " + (employeesData.messages || "Unknown error"));
            }

            employeesData = employeesData.map(e => ({
                ...e,
                status: ENTITY_STATUS_UNCHANGED
            }));

            dispatch(companyIdChanged(id));
            dispatch(companyNameChanged(companyData.name || ""));
            dispatch(companyAddress1Changed(companyData.address1 || ""));
            dispatch(companyAddress2Changed(companyData.address2 || ""));
            dispatch(companyEmployeesChanged(employeesData));
            employeesData.forEach((d, i) => {
                dispatch(userCheck(i));
                dispatch(accountActiveCheck(i, d._id));
            });
            dispatch(companyStateChanged(STATE_LOADED));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

/**
 * Redirects to new company page, sets the state to  STATE_NEW and resets all data about the company.
 */
export function companyCreated() {
    return dispatch => {
        dispatch(companyReset());
        dispatch(companyStateChanged(STATE_NEW));
    };
}

/**
 * Decides whether the company should be created or updated and posts the company to the server.
 */
export function companySaved() {
    return async (dispatch, getState) => {
        try {
            const errors = getValidationErrors(getState());
            dispatch(errorsChanged(errors));
            if (errors.length > 0) {
                toast(
                    <div>
                        This company contains the following errors:
                        <ul>
                            {errors.map((e, i) => (
                                <li key={i}>{e.message}</li>
                            ))}
                        </ul>
                    </div>,
                    {
                        type: "error",
                        autoClose: errors.length * 5000
                    }
                );
                return;
            }

            let company = getState().companies.company;

            dispatch(companyStateChanged(STATE_LOADING));
            if (company.state === STATE_NEW) {
                // the company is new, we have to create it
                let resp = await dispatch(
                    apiFetch("/api/companies/new", {
                        method: "POST",
                        body: JSON.stringify({
                            name: company.name,
                            address1: company.address1,
                            address2: company.address2,
                            contacts: company.employees
                        })
                    })
                );

                let data = await resp.json();
                if (resp.status !== 200) {
                    throw new Error(data.message);
                }

                await dispatch(loadCompaniesList());
                dispatch(push(encodeParams`/dashboard/companies/${data._id}`));
                return;
            } else if (company.state === STATE_LOADED) {
                company.toDeleteAccess.map(data => {
                    dispatch(deleteAccess(data.employeeId));
                });
                dispatch(companyToDeleteAccessDeleted());

                let resp = await dispatch(
                    apiFetch(encodeParams`/api/companies/${company.id}`, {
                        method: "POST",
                        body: JSON.stringify({
                            name: company.name,
                            address1: company.address1,
                            address2: company.address2,
                            contacts: company.employees
                        })
                    })
                );

                let companyData = await resp.json();
                if (!resp.ok) {
                    throw new Error("An error has occured: " + companyData.message);
                }
            } else {
                throw new Error("Invalid company state to save!");
            }
            // new company added or changed, refresh the search panel
            await dispatch(loadCompaniesList());
            // new company added or changed - ids of new employees are important
            await dispatch(loadCompany(company.id));
            dispatch(companyStateChanged(STATE_LOADED));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function userCheck(employeeIndex) {
    return async (dispatch, getState) => {
        try {
            let employee = getState().companies.company.employees[employeeIndex];
            let resp = await dispatch(
                apiFetch(
                    "/api/users/check",
                    {},
                    {
                        email: employee.email,
                        name: employee.name,
                        employeeId: employee._id
                    }
                )
            );
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            let response = await resp.json();
            dispatch(companyEmployeeUniqueChanged(employeeIndex, response.unique));
            dispatch(companyEmployeeExistInEmployeesIdChanged(employeeIndex, response.exsistInEmployeesId));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function accountActiveCheck(employeeIndex, employeeId) {
    return async dispatch => {
        try {
            let resp = await dispatch(apiFetch(`/api/users/employee/${employeeId}/check-active`));
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            let response = await resp.json();
            dispatch(companyEmployeeAccountStatusChanged(employeeIndex, response.accountActive));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

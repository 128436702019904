import { encodeParams, makeActionCreator } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { errorChanged, stateChanged } from "../../UsersPage/actions/usersListActions";
import { toast } from "react-toastify";

export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_NEW = "STATE_NEW";
export const STATE_CHANGED = "STATE_CHANGED";

export const COMPANY_ID_CHANGED = "DESKTOPS.DESKTOP.COMPANY_ID_CHANGED";
export const COMPANY_NAME_CHANGED = "DESKTOPS.DESKTOP.COMPANY_NAME_CHANGED";
export const COMPANY_STATE_CHANGED = "DESKTOPS.DESKTOP.COMPANY_STATE_CHANGED";
export const COMPANY_ERROR_CHANGED = "DESKTOPS.DESKTOP.COMPANY_ERROR_CHANGED";
export const COMPANY_ADDRESS1_CHANGED = "DESKTOPS.DESKTOP.COMPANY_ADDRESS1_CHANGED";
export const COMPANY_ADDRESS2_CHANGED = "DESKTOPS.DESKTOP.COMPANY_ADDRESS2_CHANGED";

export const STATE_DEFAULT = "DESKTOPS.DESKTOP.STATE_DEFAULT";
export const ORDERS_CHANGED = "DESKTOPS.DESKTOP.ORDERS_CHANGED";
export const DATA_CHANGED = "DESKTOPS.DESKTOP.DATA_CHANGED";
export const EDIT_CHANGED = "DESKTOPS.DESKTOP.EDIT_CHANGED";

export const DESKTOPS_CHANGED = "DESKTOPS.DESKTOP.DESKTOPS_CHANGED";
export const DESKTOPS_COUNT_CHANGED = "DESKTOPS.DESKTOP.DESKTOPS_COUNT_CHANGED";
export const DESKTOPS_ID_CHANGED = "DESKTOPS.DESKTOP.DESKTOPS_ID_CHANGED";

export const DESKTOPS_DAYS_RANGE_CHANGED = "DESKTOPS.DESKTOP.DESKTOPS_DAYS_RANGE_CHANGED";
export const ACTIVE_DESKTOP_CHANGED = "DESKTOPS.DESKTOP.ACTIVE_DESKTOP_CHANGED";
export const REFRESH_DESKTOP_INTERVAL_STARTED_SET = "DESKTOPS.DESKTOP.REFRESH_DESKTOP_INTERVAL_STARTED_SET";

export const GROUP_FLAG_CHANGED = "DESKTOPS.DESKTOP.GROUP_FLAG_CHANGED";
export const MODAL_INFORMATION_CHANGED = "DESKTOPS.DESKTOP.MODAL_INFORMATION_CHANGED";

export const companyStateChanged = makeActionCreator(COMPANY_STATE_CHANGED, "payload");
export const companyIdChanged = makeActionCreator(COMPANY_ID_CHANGED, "payload");
export const companyNameChanged = makeActionCreator(COMPANY_NAME_CHANGED, "payload");
export const companyAddress1Changed = makeActionCreator(COMPANY_ADDRESS1_CHANGED, "payload");
export const companyAddress2Changed = makeActionCreator(COMPANY_ADDRESS2_CHANGED, "payload");
export const companyErrorChanged = makeActionCreator(COMPANY_ERROR_CHANGED, "payload");
export const dataChanged = makeActionCreator(DATA_CHANGED, "payload");
export const orderChanged = makeActionCreator(ORDERS_CHANGED, "payload");
export const editChanged = makeActionCreator(EDIT_CHANGED, "payload");

export const desktopsChanged = makeActionCreator(DESKTOPS_CHANGED, "payload");
export const desktopsCountChanged = makeActionCreator(DESKTOPS_COUNT_CHANGED, "payload");
export const desktopsIdChanged = makeActionCreator(DESKTOPS_ID_CHANGED, "payload");

export const desktopsDaysRangeChanged = makeActionCreator(DESKTOPS_DAYS_RANGE_CHANGED, "payload");
export const activeDesktopChanged = makeActionCreator(ACTIVE_DESKTOP_CHANGED, "payload");
export const refreshDesktopIntervalStartedSet = makeActionCreator(REFRESH_DESKTOP_INTERVAL_STARTED_SET, "payload");

export const groupFlagChanged = makeActionCreator(GROUP_FLAG_CHANGED, "payload");
export const modalInformationChanged = makeActionCreator(MODAL_INFORMATION_CHANGED, "payload");

const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minute

export function loadDesktop(desktopNumber = "fromDB", loadingEffect = true) {
    return async (dispatch, getState) => {
        if (!getState().desktops.desktop.refreshDesktopIntervalStartedSet) {
            dispatch(refreshDesktopIntervalStartedSet(true));
            setTimeout(_ => dispatch(refreshDesktop()), REFRESH_INTERVAL);
        }
        try {
            if (loadingEffect) {
                dispatch(dataChanged(STATE_LOADING));
            }
            // if open desktop after click save in report we have set the connectedOrderId
            let connectedOrderId = getState().reports.report.connectedOrderId;
            //if is opening desktop first time after logging in get value of active desktop from db
            if (desktopNumber === "fromDB") {
                if (connectedOrderId) {
                    let activeDesktop = await dispatch(
                        apiFetch(`/api/desktops/order/${connectedOrderId}`, {
                            method: "PATCH"
                        })
                    );
                    let activeDesktopData = await activeDesktop.json();
                    desktopNumber = activeDesktopData.desktopNumber;
                } else {
                    let activeDesktop = await dispatch(apiFetch("/api/users/active-desktop"));
                    let activeDesktopData = await activeDesktop.json();
                    desktopNumber = activeDesktopData.activeDesktop;
                }
            } else {
                //else update last active desktop in db
                let updateActiveDesktopResp = await dispatch(
                    apiFetch("/api/users/active-desktop", {
                        method: "PATCH",
                        body: JSON.stringify({
                            activeDesktop: desktopNumber
                        })
                    })
                );
                let data = await updateActiveDesktopResp.json();
                if (updateActiveDesktopResp.status !== 200) {
                    throw new Error(data.message);
                }
            }

            //get current data to merge after reload
            let companies = getState().desktops.desktop.companies;
            let activeDesktop = getState().desktops.desktop.activeDesktop;
            let editMode = getState().desktops.desktop.edit;
            //get days range
            if (desktopNumber) {
                let daysRangeResp = await dispatch(
                    apiFetch(`/api/desktops/${encodeURIComponent(desktopNumber)}/days/range`)
                );
                let daysRangeData = await daysRangeResp.json();

                if (daysRangeResp.status !== 200) {
                    throw new Error("An error has occured: " + (daysRangeData.messages || "Unknown error"));
                }
                if (!daysRangeData || typeof daysRangeData !== "object") {
                    throw new Error("Data returned by API is not an object!");
                }
                // fetch desktops data
                // dispatch(companyStateChanged(STATE_LOADING));
                let desktopResp = await dispatch(
                    apiFetch(
                        `/api/desktops/${encodeURIComponent(desktopNumber)}/days/${encodeURIComponent(
                            daysRangeData.daysRange
                        )}`
                    )
                );
                let destkopData = await desktopResp.json();

                if (desktopResp.status !== 200) {
                    throw new Error("An error has occured: " + (destkopData.messages || "Unknown error"));
                }
                if (!destkopData || typeof destkopData !== "object") {
                    throw new Error("Data returned by API is not an object!");
                }

                //merge old data with new data from backend -
                //to new data add otherOrders
                if (activeDesktop === desktopNumber && companies.length && !connectedOrderId) {
                    for (let [key, c] of destkopData.companies.entries()) {
                        if (c.company._id === companies[key].company._id) {
                            c.otherOrders = companies[key].otherOrders;
                        }
                    }
                }

                dispatch(desktopsDaysRangeChanged(destkopData.daysRange));
                dispatch(desktopsChanged(destkopData.companies));
                dispatch(desktopsCountChanged(destkopData.countOfDesktops ? destkopData.countOfDesktops : 0));
                dispatch(desktopsIdChanged(destkopData._id));

                dispatch(activeDesktopChanged(desktopNumber));
                if (editMode) {
                    let companies = getState().desktops.desktop.companies;
                    for (let [key, c] of companies.entries()) {
                        c.otherOrders ? dispatch(loadOrders(key)) : "";
                    }
                }
            }
            dispatch(dataChanged(STATE_LOADED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function refreshDesktop(autoRefresh = true) {
    return async (dispatch, getState) => {
        try {
            if (getState().router.location.pathname !== "/dashboard/desktops") {
                return;
            }
            autoRefresh ? setTimeout(_ => dispatch(refreshDesktop()), REFRESH_INTERVAL) : "";
            dispatch(loadDesktop(getState().desktops.desktop.activeDesktop, false));
            toast("Data is up to date.", { type: "success" });
        } catch (e) {
            console.error(e);
        }
    };
}

export function deleteDesktop() {
    return async (dispatch, getState) => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let activeDesktop = getState().desktops.desktop.activeDesktop;
            let desktop = getState().desktops.desktop.id;
            let resp = await dispatch(
                apiFetch(encodeParams`/api/desktops/${desktop}`, {
                    method: "DELETE"
                })
            );
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            //redirect to previous desktop number
            dispatch(loadDesktop(activeDesktop > 1 ? activeDesktop - 1 : 1));
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function addDesktop() {
    return async (dispatch, getState) => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let countOfDesktops = getState().desktops.desktop.countOfDesktops;
            let resp = await dispatch(
                apiFetch("/api/desktops", {
                    method: "POST",
                    body: JSON.stringify({
                        userId: getState().auth.user._id,
                        daysRange: 7
                    })
                })
            );
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            dispatch(loadDesktop(countOfDesktops + 1));
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

// get data with other orders (orders who can add to the company on desktop) to used on "desktop in the company"
export function loadOrders(companyIndex) {
    return async (dispatch, getState) => {
        try {
            dispatch(dataChanged(STATE_LOADING));
            let desktopId = getState().desktops.desktop.id;
            let companies = getState().desktops.desktop.companies;
            let resp = await dispatch(apiFetch(`/api/desktops/${desktopId}/company/${companyIndex}/other-orders`));
            companies[companyIndex].otherOrders = await resp.json();
            dispatch(dataChanged(STATE_LOADED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function loadCompany(id) {
    return async dispatch => {
        try {
            // fetch company data
            dispatch(companyStateChanged(STATE_LOADING));
            let companyResp = await dispatch(apiFetch(`/api/companies/${encodeURIComponent(id)}`));

            let companyData = await companyResp.json();
            if (companyResp.status !== 200) {
                throw new Error("An error has occured: " + (companyData.messages || "Unknown error"));
            }
            if (!companyData || typeof companyData !== "object") {
                throw new Error("Data returned by API is not an object!");
            }

            // fetch order data
            let ordersResp = await dispatch(apiFetch(`/api/orders/search?query=${companyData.name}`));
            let ordersData = await ordersResp.json();

            if (ordersResp.status !== 200) {
                throw new Error("An error has occured: " + (ordersData.messages || "Unknown error"));
            }

            ordersData = ordersData.map(e => ({
                _id: e._id,
                name: e.orderNumbers.isInternalOrder
                    ? e.orderNumbers.internalOrderNumber
                    : e.orderNumbers.isStandingOrder
                    ? e.orderNumbers.standingOrderNumber
                    : e.orderNumbers.orderNumber
            }));

            dispatch(companyIdChanged(id));
            dispatch(companyNameChanged(companyData.name || ""));
            dispatch(companyAddress1Changed(companyData.address1 || ""));
            dispatch(companyAddress2Changed(companyData.address2 || ""));
            dispatch(orderChanged(ordersData));
            dispatch(companyStateChanged(STATE_LOADED));
            dispatch(dataChanged(STATE_LOADED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function addOrdersToDesktop(orders, companyNumber) {
    return async (dispatch, getState) => {
        try {
            let companies = getState().desktops.desktop.companies;
            let activeDesktop = getState().desktops.desktop.activeDesktop;
            let daysRange = getState().desktops.desktop.daysRange;
            orders.map(order => {
                companies[companyNumber].orders.push({
                    order: order,
                    orderId: order._id
                });
            });

            if (orders.length === 1) {
                companies[companyNumber].otherOrders.splice(companies[companyNumber].otherOrders.indexOf(orders[0]), 1);
            } else {
                companies[companyNumber].otherOrders.splice(0, companies[companyNumber].otherOrders.length);
            }

            dispatch(desktopsChanged(companies));
            await dispatch(updateDesktop(activeDesktop, companies));
            let desktopResp = await dispatch(
                apiFetch(
                    `/api/desktops/${encodeURIComponent(parseInt(activeDesktop))}/days/${encodeURIComponent(
                        parseInt(daysRange)
                    )}`
                )
            );

            let destkopData = await desktopResp.json();
            if (desktopResp.status !== 200) {
                throw new Error("An error has occured: " + (destkopData.messages || "Unknown error"));
            }
            if (!destkopData || typeof destkopData !== "object") {
                throw new Error("Data returned by API is not an object!");
            }
            //merge old data with new data from backend -
            //to new data add otherOrders
            for (let [key, c] of destkopData.companies.entries()) {
                if (c.company._id === companies[key].company._id) {
                    c.otherOrders = companies[key].otherOrders;
                }
            }
            dispatch(desktopsChanged(destkopData.companies));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function deleteOrdersFromDesktop(companyNumber, orderNumber = null) {
    return async (dispatch, getState) => {
        try {
            if (orderNumber) {
                let companies = getState().desktops.desktop.companies;
                let activeDesktop = getState().desktops.desktop.activeDesktop;
                let deletedOrder = companies[companyNumber].orders.splice(orderNumber, 1);
                if (companies[companyNumber].otherOrders) {
                    let resp = await dispatch(apiFetch(`/api/orders/${encodeURIComponent(deletedOrder[0].orderId)}`));
                    let data = await resp.json();
                    if (resp.status !== 200) {
                        throw new Error(data.message || "Unknown error");
                    }
                    companies[companyNumber].otherOrders.push(data);
                }
                dispatch(desktopsChanged(companies));
                await dispatch(updateDesktop(activeDesktop, companies));
            } else {
                let companies = getState().desktops.desktop.companies;
                let activeDesktop = getState().desktops.desktop.activeDesktop;
                companies[companyNumber].orders.splice(0, companies[companyNumber].orders.length);
                dispatch(desktopsChanged(companies));
                await dispatch(updateDesktop(activeDesktop, companies));
            }
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function editMode() {
    return async (dispatch, getState) => {
        try {
            let edit = getState().desktops.desktop.edit;
            dispatch(editChanged(!edit));

            if (!edit) {
                // entering edit mode
                let companies = getState().desktops.desktop.companies;
                for (const c of companies) {
                    if (c.orders.length === 0) {
                        await dispatch(loadOrders(companies.indexOf(c)));
                    }
                }
            }
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function changeAllValuesOfOrder(value) {
    return async (dispatch, getState) => {
        try {
            let ordersData = getState().desktops.desktop.orders;
            for (let o of ordersData) {
                o.value = value;
            }
            dispatch(dataChanged(STATE_CHANGED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function changedValueOfOrder(index, value) {
    return async (dispatch, getState) => {
        try {
            getState().desktops.desktop.orders[index].value = value;
            dispatch(dataChanged(STATE_CHANGED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function changeDaysRange(days) {
    return async (dispatch, getState) => {
        try {
            let activeDesktop = getState().desktops.desktop.activeDesktop;
            let companies = getState().desktops.desktop.companies;
            await dispatch(updateDesktop(activeDesktop, companies, days));
            dispatch(desktopsDaysRangeChanged(days)); //change days in scope
            dispatch(loadDesktop(activeDesktop, days)); //
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function updateDesktop(desktopNumber, desktop, daysRange = null) {
    return async dispatch => {
        try {
            let resp = null;
            //deleting unimportant data before update
            let companies = desktop.map(d => {
                let orders = d.orders.map(o => {
                    return {
                        orderId: o.orderId
                    };
                });
                return {
                    orders: orders,
                    companyId: d.companyId
                };
            });
            if (daysRange) {
                resp = await dispatch(
                    apiFetch(encodeParams`/api/desktops/${desktopNumber}`, {
                        method: "PATCH",
                        body: JSON.stringify({
                            companies: companies,
                            daysRange: daysRange
                        })
                    })
                );
            } else {
                resp = await dispatch(
                    apiFetch(encodeParams`/api/desktops/${desktopNumber}`, {
                        method: "PATCH",
                        body: JSON.stringify({
                            companies: companies
                        })
                    })
                );
            }
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message);
            }
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function deleteCompanyBox(number) {
    return async (dispatch, getState) => {
        try {
            let activeDesktop = getState().desktops.desktop.activeDesktop;
            let companies = getState().desktops.desktop.companies;
            companies.splice(number, 1);
            dispatch(companyStateChanged(STATE_LOADING));
            await dispatch(updateDesktop(activeDesktop, companies));
            dispatch(desktopsChanged(companies));
            dispatch(companyStateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function deleteOrderBox(number, companyNumber) {
    return async (dispatch, getState) => {
        try {
            let companies = getState().desktops.desktop.companies;
            let activeDesktop = getState().desktops.desktop.activeDesktop;
            let deletedOrder = companies[companyNumber].orders.splice(number, 1);
            if (companies[companyNumber].otherOrders) {
                let resp = await dispatch(apiFetch(encodeParams`/api/orders/${deletedOrder[0].orderId}`));
                let data = await resp.json();
                if (resp.status !== 200) {
                    throw new Error(data.message || "Unknown error");
                }
                companies[companyNumber].otherOrders.push(data);
            }
            dispatch(desktopsChanged(companies));
            await dispatch(updateDesktop(activeDesktop, companies));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function hideOtherOrders(companyId) {
    return async (dispatch, getState) => {
        try {
            let companies = getState().desktops.desktop.companies;

            let activeDesktop = getState().desktops.desktop.activeDesktop;

            dispatch(
                desktopsChanged(
                    companies.map(c => {
                        if (c.companyId !== companyId) return c;

                        const companyCopy = { ...c };
                        delete companyCopy.otherOrders;
                        return companyCopy;
                    })
                )
            );
            await dispatch(updateDesktop(activeDesktop, companies));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function addAllOrders(companyIndex) {
    return async (dispatch, getState) => {
        try {
            dispatch(dataChanged(STATE_LOADING));
            let companies = getState().desktops.desktop.companies[companyIndex];
            await dispatch(loadOrders(companyIndex));
            await dispatch(addOrdersToDesktop(companies.otherOrders, companyIndex));
            await dispatch(hideOtherOrders(companyIndex));
            dispatch(dataChanged(STATE_LOADED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function addOpenOrders(companyIndex) {
    return async (dispatch, getState) => {
        try {
            dispatch(dataChanged(STATE_LOADING));
            let companies = getState().desktops.desktop.companies[companyIndex];
            await dispatch(loadOrders(companyIndex));
            await dispatch(addOrdersToDesktop(companies.otherOrders.filter(o => !o.finished), companyIndex));
            await dispatch(hideOtherOrders(companyIndex));
            dispatch(dataChanged(STATE_LOADED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function deleteAllOrders(companyIndex) {
    return async dispatch => {
        try {
            await dispatch(deleteOrdersFromDesktop(companyIndex));
            await dispatch(loadOrders(companyIndex));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function clearFinishedOrders(companyIndex) {
    return async (dispatch, getState) => {
        try {
            let companies = getState().desktops.desktop.companies;

            let activeDesktop = getState().desktops.desktop.activeDesktop;

            dispatch(
                desktopsChanged(
                    companies.map((c, i) => {
                        if (i !== companyIndex) return c;
                        return {
                            ...c,
                            orders: c.orders.filter(o => !o.order.finished)
                        };
                    })
                )
            );
            await dispatch(updateDesktop(activeDesktop, getState().desktops.desktop.companies));

            await dispatch(loadOrders(companyIndex));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function followOpenAndUnfollowFinished(companyIndex) {
    return async (dispatch, getState) => {
        try {
            dispatch(dataChanged(STATE_LOADING));
            let companies = getState().desktops.desktop.companies;

            let activeDesktop = getState().desktops.desktop.activeDesktop;

            dispatch(
                desktopsChanged(
                    companies.map((c, i) => {
                        if (i !== companyIndex) return c;
                        return {
                            ...c,
                            orders: c.orders.filter(o => !o.order.finished)
                        };
                    })
                )
            );
            let company = getState().desktops.desktop.companies[companyIndex];

            let desktopId = getState().desktops.desktop.id;
            companies = getState().desktops.desktop.companies;
            let resp = await dispatch(apiFetch(`/api/desktops/${desktopId}/company/${companyIndex}/other-orders`));
            companies[companyIndex].otherOrders = await resp.json();

            await dispatch(addOrdersToDesktop(company.otherOrders.filter(o => !o.finished), companyIndex));
            dispatch(companyStateChanged(STATE_LOADING));
            await dispatch(updateDesktop(activeDesktop, getState().desktops.desktop.companies));
            await dispatch(loadOrders(companyIndex));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function changeCompaniesOrder(dragIndex, hoverIndex, dragCompany) {
    return async (dispatch, getState) => {
        try {
            let activeDesktop = getState().desktops.desktop.activeDesktop;
            let companies = getState().desktops.desktop.companies;
            companies.splice(dragIndex, 1);
            companies.splice(hoverIndex, 0, dragCompany);
            dispatch(companyStateChanged(STATE_LOADING));
            await dispatch(updateDesktop(activeDesktop, companies));
            dispatch(desktopsChanged(companies));
            dispatch(companyStateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}
export function changeOrdersOrder(dragIndex, hoverIndex, dragOrder, companyIndex) {
    return async (dispatch, getState) => {
        try {
            let activeDesktop = getState().desktops.desktop.activeDesktop;
            let companies = getState().desktops.desktop.companies;
            companies[companyIndex].orders.splice(dragIndex, 1);
            companies[companyIndex].orders.splice(hoverIndex, 0, dragOrder);
            dispatch(companyStateChanged(STATE_LOADING));
            await dispatch(updateDesktop(activeDesktop, companies));
            dispatch(orderChanged(companies));
            dispatch(companyStateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function editGroupedReport(status) {
    return async (dispatch, getState) => {
        try {
            dispatch(companyStateChanged(STATE_LOADING));
            let companies = getState().desktops.desktop.companies;
            let actualState;
            actualState = getState().desktops.desktop.groupFlag; //@todo var name to change!!!
            dispatch(groupFlagChanged(!actualState)); //change status in scope
            companies.forEach(c => {
                c.orders.forEach(o => {
                    o.reports.forEach(r => {
                        r.forEach(report => {
                            report.status === status ? (report.grouped = !report.grouped) : "";
                        });
                    });
                });
            });
            dispatch(desktopsChanged(companies));
            dispatch(companyStateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function editSingleGroupedReport(companyIndex, orderIndex, reportIndex, status) {
    return async (dispatch, getState) => {
        try {
            let companies = getState().desktops.desktop.companies;

            let actualState = getState().desktops.desktop.groupFlag;
            dispatch(groupFlagChanged(!actualState)); //change status in scope

            companies[companyIndex].orders[orderIndex].reports[reportIndex].forEach(report => {
                report.status === status ? (report.grouped = !report.grouped) : "";
            });
            dispatch(companyStateChanged(STATE_LOADING));
            dispatch(desktopsChanged(companies));
            dispatch(companyStateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(companyStateChanged(STATE_ERROR));
            dispatch(companyErrorChanged(e.message || "Unknown error"));
        }
    };
}

import React from "react";
import { Async as AsyncSelect } from "react-select";
import { connect } from "react-redux";
import { noopSelectFilter } from "../../../../utils";

import ordersHeaderSelector from "../../../../OrdersPage/selectors/ordersHeaderSelector";
import { ordersSearched } from "../../actions/orderActions";

const optionRenderer = order => (
    <div>
        <div>{order.mainNumber}</div>
        <small>{order.locationCompanyName || order.customerCompanyName}</small>
    </div>
);
@connect(
    ({ clientPanel }) => ({
        orderId: clientPanel.clientPanelExternals.order
    }),
    dispatch => ({
        ordersSearched: q => dispatch(ordersSearched(q))
    })
)
export default class OrderSearchSelect extends React.Component {
    loadOrdersOptions = async query => {
        let orders = await this.props.ordersSearched(query);
        return {
            options: ordersHeaderSelector(orders).map(order => ({
                label: order.mainNumber,
                value: order._id,
                ...order
            }))
        };
    };

    render() {
        return (
            <AsyncSelect
                ignoreAccents={false}
                placeholder="Search orders"
                loadOptions={this.loadOrdersOptions}
                value={this.props.orderId}
                onChange={this.props.onChange}
                optionRenderer={optionRenderer}
                filterOptions={noopSelectFilter}
                disabled={this.props.disabled}
            />
        );
    }
}

import {
    ORDER_NUMBER_CHANGED,
    INTERNAL_ORDER_NUMBER_CHANGED,
    STANDING_ORDER_NUMBER_CHANGED,
    IS_INTERNAL_ORDER_CHANGED,
    IS_STANDING_ORDER_CHANGED,
    ORDER_RECIEVED_DATE_CHANGED,
    INTERNAL_NUMBER_AVAILABILTY_STATE_CHANGED,
    INA_STATE_DEFAULT
} from "../actions/orderNumberActions";
import { ORDER_DATA_CHANGED, ORDER_RESET } from "../actions/orderActions";
import { getCurrentWeekNumber } from "../../utils";

const makeInitialState = _ => ({
    orderNumber: getCurrentWeekNumber().toString().padLeft(2, "0") + "XX/" + new Date().getFullYear(),
    standingOrderNumber: "STXX/" + new Date().getFullYear(),
    internalOrderNumber: "",
    isStandingOrder: false,
    isInternalOrder: false,
    orderRecievedDate: new Date(),
    inaState: INA_STATE_DEFAULT
});

export default function orderNumbers(state = makeInitialState(), action) {
    let extraData = {};
    switch (action.type) {
        case ORDER_DATA_CHANGED:
            return action.payload.orderNumbers;
        case ORDER_RESET:
            return makeInitialState();
        case ORDER_NUMBER_CHANGED:
            return {
                ...state,
                orderNumber: action.payload
            };
        case INTERNAL_ORDER_NUMBER_CHANGED:
            return {
                ...state,
                internalOrderNumber: action.payload
            };
        case STANDING_ORDER_NUMBER_CHANGED:
            return {
                ...state,
                standingOrderNumber: action.payload
            };
        case IS_INTERNAL_ORDER_CHANGED:
            if (action.payload === false) {
                extraData.inaState = INA_STATE_DEFAULT;
            }
            return {
                ...state,
                ...extraData,
                isInternalOrder: action.payload,
                isStandingOrder: false
            };
        case IS_STANDING_ORDER_CHANGED:
            if (action.payload === true) {
                extraData.inaState = INA_STATE_DEFAULT;
            }
            return {
                ...state,
                ...extraData,
                isStandingOrder: action.payload,
                isInternalOrder: false
            };
        case ORDER_RECIEVED_DATE_CHANGED:
            return {
                ...state,
                orderRecievedDate: action.payload
            };
        case INTERNAL_NUMBER_AVAILABILTY_STATE_CHANGED:
            if (!state.isInternalOrder) {
                // discard state changes when order is not internal
                return {
                    ...state,
                    inaState: INA_STATE_DEFAULT
                };
            }
            return {
                ...state,
                inaState: action.payload
            };
        default:
            return state;
    }
}

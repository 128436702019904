import {
    STATE_DEFAULT,
    STATE_CHANGED,
    ERROR_CHANGED,
    USERS_CHANGED,
    GROUP_FILTER_CHANGED,
    TEXT_SEARCH_CHANGED
} from "../actions/usersListActions";

export const initialState = {
    users: [],
    error: null,
    state: STATE_DEFAULT,
    groupFilter: null,
    textSearch: ""
};

export default function usersListReducer(state = initialState, action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case USERS_CHANGED:
            return {
                ...state,
                users: action.payload
            };
        case GROUP_FILTER_CHANGED:
            return {
                ...state,
                groupFilter: action.payload
            };
        case TEXT_SEARCH_CHANGED:
            return {
                ...state,
                textSearch: action.payload
            };
        default:
            return state;
    }
}

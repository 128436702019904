import React from "react";

//const columnToTh = (col, i) => <th>{col.header}</th>

export default class AutoTableHeader extends React.Component {
    render() {
        return (
            <thead>
                <tr>
                    {this.props.model.columns.map((c, i) => (
                        <th key={i}>{c.title}</th>
                    ))}
                </tr>
            </thead>
        );
    }
}

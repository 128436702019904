import {
    ORDER_CHANGED,
    STATE_CHANGED,
    EXTERNALS_CHANGED,
    STATE_DEFAULT,
    DATE_START_CHANGED,
    DATE_END_CHANGED,
    ORDER_INFORMATION_CHANGED
} from "../actions/customerPanelExternalsActions";

export const initialState = {
    order: null,
    dateStart: null,
    dateEnd: null,
    state: STATE_DEFAULT,
    externals: null,
    orderInformation: null
};

export default function clientPanelExternals(state = initialState, action) {
    switch (action.type) {
        case ORDER_CHANGED:
            return {
                ...state,
                order: action.payload
            };
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case EXTERNALS_CHANGED:
            return {
                ...state,
                externals: action.payload
            };
        case DATE_START_CHANGED:
            return {
                ...state,
                dateStart: action.payload
            };
        case DATE_END_CHANGED:
            return {
                ...state,
                dateEnd: action.payload
            };
        case ORDER_INFORMATION_CHANGED:
            return {
                ...state,
                orderInformation: action.payload
            };
        default:
            return state;
    }
}

export const VERROR_EMPLOYEE_TT_NOT_SELECTED = "EMPLOYEE_TT_NOT_SELECTED";
export const VERROR_EMPLOYEE_TT_INVALID_DATE_OF_BIRTH = "EMPLOYEE_TT_INVALID_DATE_OF_BIRTH";
export const VERROR_EMPLOYEE_TT_TIME_CONFLICT = "EMPLOYEE_TT_TIME_CONFLICT";
export const VERROR_EMPLOYEE_TT_DUPLICATE_ROWS = "EMPLOYEE_TT_DUPLICATE_ROWS";
export const VERROR_INVALID_CONTROL_DATE = "INVALID_CONTROL_DATE";
export const VERROR_NO_REPORT_NAME = "NO_REPORT_NAME"; // No Unofficial order name
export const VERROR_NO_CONNECTED_ORDER = "NO_CONNECTED_ORDER";
export const VERROR_QA_REWORKED_AND_NOK_GREATER_THAN_TOTAL_CHECKED = "QA_REWORKED_AND_NOK_GREATER_THAN_TOTAL_CHECKED";
export const VERROR_NO_REMARKS_WHEN_TOTAL_IS_ZERO = "NO_REMARKS_WHEN_TOTAL_IS_ZERO";

export const validationErrors = {
    [VERROR_EMPLOYEE_TT_NOT_SELECTED]: rowIndex => ({
        type: VERROR_EMPLOYEE_TT_NOT_SELECTED,
        rowIndex,
        message: "Employee not selected."
    }),
    [VERROR_EMPLOYEE_TT_INVALID_DATE_OF_BIRTH]: rowIndex => ({
        type: VERROR_EMPLOYEE_TT_INVALID_DATE_OF_BIRTH,
        rowIndex,
        message: "Invalid date of birth for employee."
    }),
    [VERROR_EMPLOYEE_TT_TIME_CONFLICT]: rowIndex => ({
        type: VERROR_EMPLOYEE_TT_TIME_CONFLICT,
        rowIndex,
        message: "Employee has a time overlap conflict."
    }),
    [VERROR_EMPLOYEE_TT_DUPLICATE_ROWS]: rowIndex => ({
        type: VERROR_EMPLOYEE_TT_DUPLICATE_ROWS,
        rowIndex,
        message: "Duplicate employee row with time overlap."
    }),
    [VERROR_INVALID_CONTROL_DATE]: () => ({
        type: VERROR_EMPLOYEE_TT_DUPLICATE_ROWS,
        message: "Invalid control date."
    }),
    [VERROR_NO_REPORT_NAME]: () => ({
        type: VERROR_NO_REPORT_NAME,
        message: "Missing unofficial order name."
    }),
    [VERROR_NO_CONNECTED_ORDER]: () => ({
        type: VERROR_NO_CONNECTED_ORDER,
        message: "Connected order not selected."
    }),
    [VERROR_QA_REWORKED_AND_NOK_GREATER_THAN_TOTAL_CHECKED]: rowIndex => ({
        type: VERROR_QA_REWORKED_AND_NOK_GREATER_THAN_TOTAL_CHECKED,
        rowIndex,
        message: "Reworked and nok is greater than total checked."
    }),
    [VERROR_NO_REMARKS_WHEN_TOTAL_IS_ZERO]: () => ({
        type: VERROR_NO_REMARKS_WHEN_TOTAL_IS_ZERO,
        message: "The report must have remarks when there are no checked parts."
    })
};

import React from "react";
import {
    STATE_LOADING,
    STATE_ERROR,
    loadExternalFromReportId,
    STATE_LOADED,
    externalModalOpenChanged
} from "../actions/externalActions";
import { connect } from "react-redux";
import StyledIcon from "../../components/common/StyledIcon";
import { Panel, Modal } from "react-bootstrap";
import ExternalHeaderBar from "./ExternalHeaderBar";
import CurrentReportForm from "./CurrentReportForm";
import OldExternal from "./OldExternal";
import styled from "styled-components";

const StyledModal = styled(Modal)`
    .control-label {
        transform: translateY(1px);
    }
    .modal-content {
        max-width: 100vw;
    }
`;

@connect(
    ({ externals }) => ({
        ...externals.external,
        ...externals.oldExternals
    }),
    dispatch => ({
        closeExternalModal: _ => dispatch(externalModalOpenChanged(false))
    })
)
export default class ExternalsModal extends React.Component {
    render() {
        let modalBody = null;
        if (this.props.state === STATE_LOADING) {
            modalBody = (
                <div>
                    <StyledIcon spin center size="big" />
                </div>
            );
        } else if (this.props.state === STATE_ERROR) {
            modalBody = (
                <div>
                    <Panel header="Error" bsStyle="danger">
                        {this.props.error}
                    </Panel>
                </div>
            );
        } else if (this.props.state === STATE_LOADED) {
            modalBody = (
                <div>
                    <ExternalHeaderBar />
                    {this.props.oldExternals.filter(o => o._id !== this.props.id).map(o => <OldExternal external={o} key={o._id} />)}
                    <CurrentReportForm />
                </div>
            );
        }

        return (
            <StyledModal show={this.props.externalModalOpen} onHide={this.props.closeExternalModal} bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title>Approve data for external</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
            </StyledModal>
        );
    }
}

import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import styled from "styled-components";
import {
    PERMISSION_CLIENT_PANEL,
    PERMISSION_VIEW_USERS,
    PERMISSION_VIEW_EMPLOYEES,
    PERMISSION_EDIT_EMPLOYEES,
    PERMISSION_CREATE_TEMPLATES
} from "../../../../common/src/permissions";
import { authCheckRequested, STATE_LOGGED_IN } from "../../actions/authActions";
import { loadNotifications } from "../../actions/notificationsActions";
import LoadableCompaniesPage from "../../CompaniesPage/components/LoadableCompaniesPage";
import LoadableMainsPage from "../../DesktopsPage/components/LoadableMainsPage";
import ExternalsModal from "../../ExternalsModal/components/ExternalsModal";
import LoadableOfficePage from "../../OfficePage/components/LoadableOfficePage";
import LoadableOrdersPage from "../../OrdersPage/components/LoadableOrdersPage";
import LoadableReportsPage from "../../ReportsPage/components/LoadableReportsPage";
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import LoadableUsersPage from "../../UsersPage/components/LoadableUsersPage";
import DashboardFooter from "./DashboardFooter";
import DashboardNavBar from "./DashboardNavBar";
import CustomerPage from "../../CustomerPanel/components/CustomerPage";
import InvitationModal from "../../InvitationModal/components/InvitationModal";
import LoadableEmployeesListPage from "../../HRPage/components/LoadableEmployeesListPage";
import ContactPersonModal from "../../ContactPersonModal/components/ContactPersonModal";
import EditEmployeePage from "../../HRPage/components/EditEmployeePage";
import EditTemplatePage from "../../HRPage/components/templates/EditTemplatePage";
import TemplatesListPage from "../../HRPage/components/templates/TemplatesListPage";
import DashboardSidebar from "./DashboardSidebar";

const DashboardBody = styled.div`
    min-height: 80vh;
`;

const SidebarAndContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    .container {
        overflow-x: auto;
    }
`;

@connect(
    state => ({
        userTabEnabled: userHasPermissionSelector(state, PERMISSION_VIEW_USERS),
        enableClientPanelPage: userHasPermissionSelector(state, PERMISSION_CLIENT_PANEL),
        hrTabEnabled: userHasPermissionSelector(state, PERMISSION_VIEW_EMPLOYEES),
        hideDashboard:
            state.auth.authState !== STATE_LOGGED_IN || userHasPermissionSelector(state, PERMISSION_CLIENT_PANEL),
        editEmployeeEnabled: userHasPermissionSelector(state, PERMISSION_EDIT_EMPLOYEES),
        editTemplateEnabled: userHasPermissionSelector(state, PERMISSION_CREATE_TEMPLATES),
        pathName: state.router.location.pathname
    }),
    dispatch => ({
        authCheckRequested: _ => dispatch(authCheckRequested()),
        loadNotifications: _ => dispatch(loadNotifications())
    })
)
export default class DashboardPage extends React.Component {
    componentDidMount() {
        this.props.authCheckRequested();
        this.props.loadNotifications();
    }
    render() {
        let showDashboard =
            this.props.pathName.indexOf("/dashboard/simulations/customer/") === -1 && !this.props.hideDashboard;
        return (
            <div>
                {showDashboard && (
                    <div>
                        <DashboardNavBar />

                        <SidebarAndContentWrapper>
                            <DashboardSidebar />

                            <div className="container">
                                <DashboardBody>
                                    <Route
                                        component={LoadableReportsPage}
                                        path={`${this.props.match.url}/reports`}
                                        exact
                                    />
                                    <Route
                                        component={LoadableReportsPage}
                                        path={`${this.props.match.url}/reports/:id`}
                                        exact
                                    />
                                    <Route
                                        component={LoadableReportsPage}
                                        path={`${this.props.match.url}/reports/:id/version/:versionId`}
                                        exact
                                    />
                                    <Route
                                        component={LoadableCompaniesPage}
                                        path={`${this.props.match.url}/companies`}
                                    />
                                    <Route
                                        component={LoadableOrdersPage}
                                        path={`${this.props.match.url}/orders`}
                                        exact
                                    />
                                    <Route
                                        component={LoadableOrdersPage}
                                        path={`${this.props.match.url}/orders/:id`}
                                        exact
                                    />
                                    <Route
                                        component={LoadableOrdersPage}
                                        path={`${this.props.match.url}/orders/:id/version/:versionId`}
                                        exact
                                    />
                                    <Route component={LoadableOfficePage} path={`${this.props.match.url}/office`} />
                                    {this.props.userTabEnabled && (
                                        <Route component={LoadableUsersPage} path={`${this.props.match.url}/users`} />
                                    )}
                                    {this.props.hrTabEnabled && [
                                        <Route
                                            component={LoadableEmployeesListPage}
                                            path={`${this.props.match.url}/hr`}
                                            key={1}
                                            exact
                                        />,
                                        this.props.editEmployeeEnabled && (
                                            <Route
                                                component={EditEmployeePage}
                                                path={`${this.props.match.url}/hr/employee/:id`}
                                                key={2}
                                            />
                                        ),
                                        this.props.editTemplateEnabled && (
                                            <Route
                                                component={EditTemplatePage}
                                                path={`${this.props.match.url}/hr/templates/:id`}
                                                key={3}
                                            />
                                        ),
                                        this.props.editTemplateEnabled && (
                                            <Route
                                                component={TemplatesListPage}
                                                path={`${this.props.match.url}/hr/templates`}
                                                key={4}
                                                exact
                                            />
                                        )
                                    ]}
                                    <Route component={LoadableMainsPage} path={`${this.props.match.url}/desktops`} />
                                </DashboardBody>
                                <DashboardFooter />
                                <ExternalsModal />
                                <InvitationModal />
                                <ContactPersonModal />
                            </div>
                        </SidebarAndContentWrapper>
                    </div>
                )}
                <Route path={`${this.props.match.url}/simulations/customer/:id`} component={CustomerPage} exact />
            </div>
        );
    }
}

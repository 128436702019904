import React from "react";
import { connect } from "react-redux";
import { STATE_LOADED } from "../../actions/customerPanelExternalsActions";
import { TableBody, Container, TableCustom } from "./Styles";
import { Col } from "react-bootstrap";
import styled from "styled-components";

const InformationHeader = styled.h4`
    margin-left: 10px;
    font-weight: bold; 
    font-size: 14px;
`;

@connect(({ clientPanel }) => clientPanel.clientPanelExternals)
export default class ContactPerson extends React.Component {
    render() {
        let headerContactPerson = "";
        let contentCustomerContactPerson = "";
        let contentLocationContactPerson = "";
        if (this.props.state === STATE_LOADED) {
            let customerContactPerson = this.props.orderInformation.customerContactPerson;
            let locationContactPerson = this.props.orderInformation.locationContactPerson;

            headerContactPerson = (
                <tr>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Phone Number</th>
                </tr>
            );

            contentCustomerContactPerson = customerContactPerson.map(p => {
                return (
                    <tr key={p._id}>
                        <td>{p.name}</td>
                        <td>{p.email}</td>
                        <td>{p.phoneNumber ? p.phoneNumber : "n/a"}</td>
                    </tr>
                );
            });
            contentLocationContactPerson = locationContactPerson.map(p => {
                return (
                    <tr key={p._id}>
                        <td>{p.name}</td>
                        <td>{p.email}</td>
                        <td>{p.phoneNumber ? p.phoneNumber : "n/a"}</td>
                    </tr>
                );
            });
        }
        return (
            <div>
                {this.props.orderInformation.customerCompanyName && <Col md={6}>
                    <Container>
                        <TableCustom responsive={true}>
                            <InformationHeader>{this.props.orderInformation.customerCompanyName}</InformationHeader>
                            <TableBody>
                                <tbody>
                                    {headerContactPerson}
                                    {contentCustomerContactPerson}
                                </tbody>
                            </TableBody>
                        </TableCustom>
                    </Container>
                </Col>}
                {this.props.orderInformation.locationCompanyName && <Col lg={6}>
                    <Container>
                        <TableCustom responsive={true}>
                            <InformationHeader>{this.props.orderInformation.locationCompanyName}</InformationHeader>
                            <TableBody>
                                <tbody>
                                    {headerContactPerson}
                                    {contentLocationContactPerson}
                                </tbody>
                            </TableBody>
                        </TableCustom>
                    </Container>
                </Col>}
            </div>
        );
    }
}